import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet-async"

const Features = [
  {
    category: "Puzzles",
    items: [
      'Filter puzzles on the "Available Puzzles" page',
      "Add more fields to puzzles including Box Condition, Dimensions, etc",
      "Automatically link puzzles based on title/manufacturer",
      "View all puzzles for a given brand",
      "Rate puzzles you've completed",
      "Delete Puzzles",
    ],
  },
  {
    category: "Trades",
    items: [
      "Rate users you've traded with",
      "Counter-offers",
      "Multi-puzzle trades",
      "One-way swaps (aka gifts/donations)",
      "Shipping cost estimator",
      "USPS Address Validation",
    ],
  },
  {
    category: "Users",
    items: [
      "Message other users",
      "Friend/Block other users",
      "Discussion Forum",
      "Statistics for how many puzzles and trades completed",
      "Deactivate Accounts",
    ],
  },
]

const FeatureList = ({ category, items }) => {
  return (
    <div>
      <div className="is-size-4">{category}:</div>
      <div style={{ marginLeft: "3rem" }}>
        {items.map((i) => (
          <FeatureListItem item={i} />
        ))}
      </div>
    </div>
  )
}

const FeatureListItem = ({ item }) => {
  return (
    <div>
      <span>
        <i className="fas fa-puzzle-piece has-text-primary" style={{ marginRight: "1rem", marginTop: "0.5rem" }}></i> {item}
      </span>
    </div>
  )
}

const SneakPeek = () => {
  return (
    <>
      <Helmet title="Upcoming Features - JiggSwap" />
      <div className="site-content">
        <div className="is-size-4 has-text-centered">
          Here's a list of some of features we hope to bring to JiggSwap soon. <br />
          (Updated 06/16/2020):
        </div>

        {Features.map((f) => (
          <FeatureList category={f.category} items={f.items} />
        ))}

        <hr />
        <p className="has-text-centered">
          JiggSwap is actively being developed! If you have comments, opinions, suggestions, requests, questions, or just want to chat about
          puzzles, you can use the <Link to="/contact">Contact Us</Link> page or email us at:{" "}
          <a href="mailto:andrew@jiggswap.com">andrew@jiggswap.com</a>.
        </p>
        <p className=" has-text-centered">We'd love to hear your thoughts and ideas to make Jiggswap even better!</p>
      </div>
    </>
  )
}

export default SneakPeek
